import React from "react";
import tw from "twin.macro";

import Layout from "../components/Layout";
import PageWrapper from "../components/PageWrapper";
import Content from "../components/Content";
import ContentWrapper from "../components/ContentWrapper";
import SideWrapper from "../components/SideWrapper";
import Sidenavs from "../components/Sidenavs";
import SponsorSlider from "../components/SponsorSlider";
import SponsorTop from "../components/SponsorTop";

const Sponsor = ({ pageContext }) => {
  const { logo, content, name, contact, categories, level, url } =
    pageContext.sponsor;

  return (
    <Layout>
      <PageWrapper>
        <ContentWrapper>
          <SponsorTop
            name={name}
            logo={logo}
            contact={contact}
            level={level}
            categories={categories}
            url={url}
          />
          <h3 tw="text-3xl font-din text-primary font-bold mb-2">About</h3>
          <Content>{content}</Content>
        </ContentWrapper>
        <SideWrapper>
          <Sidenavs />
          <SponsorSlider sidebar />
        </SideWrapper>
      </PageWrapper>
    </Layout>
  );
};

export default Sponsor;
