import React from "react";
import tw from "twin.macro";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { stripPhoneNumber } from "../../lib/functions";

import { FaPhoneAlt, FaEnvelope } from "react-icons/fa";

const Section = tw.section`w-full flex flex-col relative mb-12`;
const Left = tw.div`flex flex-col w-full sm:max-w-[35%]`;
const Right = tw.div`flex flex-col w-full gap-6 justify-between mb-6 relative`;
const Contact = tw.div`flex flex-col gap-1 w-full relative`;
const Membership = tw.div`flex flex-col gap-1 w-full relative`;
const Categories = tw.div`flex flex-col gap-1 w-full relative`;
const Website = tw.a`text-sm font-semibold text-primary hover:text-bright`;
const Link = tw.a`hover:text-secondary`;

const SponsorTop = ({
  name,
  contact,
  categories,
  level,
  logo,
  url,
  ...rest
}) => {
  return (
    <Section {...rest}>
      <h1 tw="font-din text-3xl sm:text-4xl mb-8">{name}</h1>
      <div tw="w-full relative flex flex-col sm:flex-row justify-start gap-12 items-center">
        <Left>
          <div tw="my-7">
            <GatsbyImage image={getImage(logo.localFile)} alt={logo.altText} />
          </div>
        </Left>
        <Right>
          <Contact>
            <p tw="text-xl font-din">Contact:</p>
            <p tw="font-bold">{contact.name}</p>
            {contact.phone && (
              <div tw="flex flex-row gap-4 items-center leading-none">
                <FaPhoneAlt />
                <Link href={`tel:${stripPhoneNumber(contact.phone)}`}>
                  {contact.phone}
                </Link>
              </div>
            )}
            {contact.email && (
              <div tw="flex flex-row gap-4 leading-none items-center">
                <FaEnvelope />
                <Link href={`mailto:${contact.email}`}>{contact.email}</Link>
              </div>
            )}
          </Contact>
          <Membership>
            <p tw="text-xl font-din">Advocate Level:</p>
            <div tw="flex flex-row gap-2">
              {level.map((each, i) => (
                <span tw="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700">
                  {each}
                </span>
              ))}
            </div>
          </Membership>
          <Categories>
            <p tw="text-xl font-din">Areas of Expertise:</p>
            <div tw="flex flex-row flex-wrap gap-2 w-full">
              {categories.map((each, i) => (
                <span tw="inline-block bg-gray-200 whitespace-nowrap rounded-full px-3 py-1 text-sm font-semibold text-gray-700">
                  {each}
                </span>
              ))}
            </div>
          </Categories>
          <Website target="_blank" href={url}>
            Visit Website
          </Website>
        </Right>
      </div>
    </Section>
  );
};

export default SponsorTop;
